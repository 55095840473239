<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-12 col-sm-6 col-md-4"
        v-model="attendedDate"
        label="วันที่"
        isNull>
      </sgv-input-date>
    </div>

    <sgv-table
      ref="contactList"
      :rKey="rKey"
      :items="contacts"
      :headers="headers"
      :filter.sync="filter"
      :options.sync="options">

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.code">
            <span
              class="text-success px-2 pointer"
              @click="addDoc(item.id)">
              <fa icon="plus"></fa>
            </span>
            {{item.code}} ({{item.name}})
          </td>
          <td v-if="hidden.jobDescriptions">
            {{item.jobDescriptions.map(v => v.position).join(', ')}}
          </td>
        </tr>
      </template>

      <button
        slot="action"
        class="btn btn-link text-success"
        v-if="$auth.hasRole(`doc:${docType}:add`)"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
    </sgv-table>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_UNCREATED_CONTACT,
  CREATE_DOC
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docStatus: {
      type: String,
      required: false
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}ListUnCreatedContact`,
      headers: [
        {text: 'พนักงาน', value: 'code'},
        {text: 'ตำแหน่ง', value: 'jobDescriptions'},
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['code', 'jobDescriptions'],
        column: null,
        search: null,
        toolbar: null,
      },
      attendedDate: '',
      rFields: ['attendedDate', 'filter', 'options'],
      contacts: [],
      isAdding: false
    }
  },
  apollo: {
    contacts: {
      query () {
        return LIST_UNCREATED_CONTACT(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          docType: this.docType,
          attendedDate: this.attendedDate
        }
      },
      skip () {
        return !this.attendedDate
      },
      debounce: 150,
      fetchPolicy: 'network-only',
    }
  },
  methods: {
    async createDoc (contactId) {
      const input = {
        contactId,
        attendedDates: [this.attendedDate]
      }
      return this.$apollo.mutate({
        mutation: CREATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
    },
    async addDoc (contactId) {
      this.createDoc(contactId)
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.contacts.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    async addAll () {
      for (let i = 0; i < this.contacts.length; i++) {
        const contact = this.contacts[i]
        await this.createDoc(contact.id)
        .then(() => {
          this.$toasted.global.success("เพิ่มสำเร็จ")
        })
        .catch(err => {
          this.$toasted.global.error(err)
        })
      }
      this.$apollo.queries.contacts.refetch()
    }
  }
}
</script>

<style lang="css" scoped>
</style>
