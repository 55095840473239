<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-6"
        v-model="startDate"
        label="เริ่ม"
        isNull
        :validations="[
          {text: 'required!', value: $v.startDate.$dirty && $v.startDate.$error}
        ]">
      </sgv-input-date>

      <sgv-input-date
        class="col-6"
        v-model="endDate"
        label="ถึง"
        isNull
        :validations="[
          {text: 'required!', value: $v.endDate.$dirty && $v.endDate.$error}
        ]">
      </sgv-input-date>

      <DocConfigFormJobDescription
        label="ตำแหน่งงาน"
        :docType="docType"
        :templateType="templateType"
        class="col-6"
        select="id"
        v-model="jobDescriptionId">
      </DocConfigFormJobDescription>

      <DocConfigFormContact
        :docType="docType"
        :templateType="templateType"
        class="col-6"
        select="id"
        :types="['employee']"
        v-model="contactId">
      </DocConfigFormContact>
    </div>

    รายงาน PDF
    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          label="ตำแหน่งงาน"
          inline
          v-model="isJobDescription">
        </sgv-input-check>

        <sgv-input-check
          label="รายละเอียดเข้างาน"
          inline
          v-model="isDetail">
        </sgv-input-check>

        <sgv-input-check
          label="รายการคำนวณ"
          inline
          v-model="isProfile">
        </sgv-input-check>
      </div>
    </div>

    <ListReportPrinter
      class="mb-3"
      :docType="docType"
      :templateType="templateType"
      @search="fetchData"
      @print="printData"
      @download="downloadData">
      ค้นหา
    </ListReportPrinter>

    <div v-if="isFetching">
      กำลังค้นหา...
    </div>

    <div v-else-if="items.length === 0">
      ไม่พบข้อมูล
    </div>

    <div v-else>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            สรุปผล
          </h5>

          <div class="mb-2">
            ยอดรวม:
            <span class="text-success">
              {{summary.netPrice | comma}}
            </span>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6">
              รายได้:
              <ul v-if="summary.debits.length > 0">
                <li
                  v-for="debit in summary.debits"
                  :key="debit.value">
                  {{debit.text}}:
                  <span
                    v-if="debit.totalPrice !== 0"
                    class="text-success">
                    {{debit.totalPrice | comma}}
                  </span>
                  <span v-if="debit.totalPrice === 0">-</span>
                </li>
              </ul>
            </div>

            <div class="col-12 col-sm-6">
              รายการหัก:
              <ul v-if="summary.credits.length > 0">
                <li
                  v-for="credit in summary.credits"
                  :key="credit.value">
                  {{credit.text}}:
                  <span
                    v-if="credit.totalPrice !== 0"
                    class="text-danger">
                    {{credit.totalPrice | comma}}
                  </span>
                  <span v-if="credit.totalPrice === 0">-</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card mt-3"
        v-for="contact in items"
        :key="contact.id">
        <div class="card-body">
          <h5 class="card-title">
            {{contact.code}} {{contact.name}}
          </h5>
          <h6 class="card-subtitle mb-2 text-muted">
            {{contact.jobDescription}}
          </h6>

          <div class="row">
            <div class="mb-3 col-12">
              OT:
              <span class="text-success">{{contact.attendances.ot.count}}</span> ครั้ง
              --- รวม
              <span class="text-success">{{contact.attendances.ot.total}}</span> นาที
            </div>

            <div class="col-12 col-sm-6">
              สาย:
              <span class="text-warning">{{contact.attendances.late.count}}</span> ครั้ง
              --- รวม
              <span class="text-warning">{{contact.attendances.late.total}}</span> นาที
              <small>
                <ul>
                  <li
                    v-for="att in contact.attendances.late.attendances"
                    :key="att.attendedDate">
                    {{att.attendedDate}} (<span class="text-warning">{{att.late}}</span>) นาที
                    <div v-if="att.description">
                      {{att.description}}
                    </div>
                    <div v-if="att.remark" class="text-primary">
                      {{att.remark}}
                    </div>
                  </li>
                </ul>
              </small>
            </div>

            <div class="col-12 col-sm-6">
              อื่นๆ:
              <small>
                <ul>
                  <li
                    v-for="att in contact.attendances.remarks"
                    :key="att.attendedDate">
                    {{att.attendedDate}}
                    <div v-if="att.description">
                      {{att.description}}
                    </div>
                    <div v-if="att.remark" class="text-primary">
                      {{att.remark}}
                    </div>
                  </li>
                </ul>
              </small>
            </div>
          </div>


          <div class="mb-2">
            ยอดรวม:
            <span class="text-success">
              {{contact.netPrice | comma}}
            </span>
          </div>

          <div class="row">
            <div class="col-12 col-sm-6">
              รายได้:
              <ul v-if="contact.debits.length > 0">
                <li
                  v-for="debit in contact.debits"
                  :key="debit.value">
                  {{debit.text}}:
                  <span
                    v-if="debit.totalPrice !== 0"
                    class="text-success">
                    {{debit.totalPrice | comma}}
                  </span>
                  <span v-if="debit.totalPrice === 0">-</span>
                  <span
                    v-if="debit.isQty"
                    class="text-info">
                    ({{debit.qty}})
                  </span>
                </li>
              </ul>
            </div>

            <div class="col-12 col-sm-6">
              รายการหัก:
              <ul v-if="contact.credits.length > 0">
                <li
                  v-for="credit in contact.credits"
                  :key="credit.value">
                  {{credit.text}}:
                  <span
                    v-if="credit.totalPrice !== 0"
                    class="text-danger">
                    {{credit.totalPrice | comma}}
                  </span>
                  <span v-if="credit.totalPrice === 0">-</span>
                  <span
                    v-if="credit.isQty"
                    class="text-info">
                    ({{credit.qty}})
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import { LIST_REPORT_PAYROLL } from './graph'
import DocConfigFormContact from '@/views/doc_core/components/DocConfigFormContact'
import DocConfigFormJobDescription from '@/views/doc_core/components/DocConfigFormJobDescription'
import ListReportPrinter from '@/views/doc_core/components/ListReportPrinter'


export default {
  mixins: [retainMixin],
  props: {
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      items: [],
      startDate: '',
      endDate: '',
      contactId: null,
      jobDescriptionId: null,
      isFetching: false,
      isJobDescription: false,
      isDetail: false,
      isProfile: false,
      rKey: `Doc${this.$form.capitalize(this.docType)}ReportPayroll`,
      rFields: [
        'startDate', 'endDate',
        'contactId', 'jobDescriptionId',
        'isJobDescription', 'isDetail', 'isProfile'
      ],
      profileTypes: [
        {text: 'เงินเดือน', value: 'salary', isIncome: true, isQty: true},
        {text: 'เงินล่วงเวลา', value: 'ot', isIncome: true, isQty: true},
        {text: 'เงินได้หน้าที่', value: 'duty', isIncome: true},
        {text: 'สวัสดิการ', value: 'welfare', isIncome: true},
        {text: 'เงินรางวัล', value: 'premium', isIncome: true},
        {text: 'เงินได้อื่นๆ', value: 'otherIncome', isIncome: true},

        {text: 'หักบกพร่อง', value: 'defectDuty', isIncome: false},
        {text: 'หักสวัสดิการ', value: 'defectWelfare', isIncome: false},
        {text: 'หักอื่นๆ', value: 'defectIncome', isIncome: false},
        {text: 'หักกยศ', value: 'studentLoan', isIncome: false},
        {text: 'หักปกสค.', value: 'socialSecurity', isIncome: false},
        {text: 'หักภาษี', value: 'tax', isIncome: false},
      ],
      url: `/doc/${this.docType}/print/report/payroll`
    }
  },
  validations: {
    startDate: {required},
    endDate: {required}
  },
  computed: {
    summary () {
      const debits = this.sumBy(this.items.flatMap(contact => contact.debits))
      const credits = this.sumBy(this.items.flatMap(contact => contact.credits))
      const netPrice = this.items.reduce((t,v) => t += v.netPrice, 0)

      return {netPrice, debits, credits}
    }
  },
  methods: {
    sumBy (arr) {
      return arr.reduce((t,v) => {
        const found = t.find(f => f.value === v.value)

        if (!found) {
          t.push({
            text: v.text,
            value: v.value,
            isIncome: v.isIncome,
            isQty: v.isQty,
            totalPrice: v.totalPrice
          })
        } else {
          found.totalPrice += v.totalPrice
        }
        return t
      }, [])
    },
    serializeData (contact) {
      const profiles = this.profileTypes.map(profileType => {
        const profile = contact.profiles.find(v => v.type === profileType.value)
        return {
          text: profileType.text,
          value: profileType.value,
          isIncome: profileType.isIncome,
          isQty: profileType.isQty,
          qty: profile?.qty || 0,
          totalPrice: profile?.totalPrice || 0,
        }
      })

      const netPrice = contact.profiles.find(v => v.type === 'netPrice')

      return {
        id: contact.id,
        type: contact.type,
        code: contact.code,
        name: contact.name,
        jobDescription: this.getJobDescriptionText(contact.jobDescriptions),
        attendances: contact.attendances,
        debits: profiles.filter(v => v.isIncome),
        credits: profiles.filter(v => !v.isIncome),
        netPrice: netPrice.totalPrice
      }
    },
    getJobDescriptionText (jobDescriptions) {
      return jobDescriptions.map(v => v.position).join(', ') || ''
    } ,
    serializeInput () {
      const filter = {
        docType: this.docType,
        startDate: this.startDate,
        endDate: this.endDate,
      }

      if (this.contactId) {
        filter.contactId = this.contactId
      }

      if (this.jobDescriptionId) {
        filter.jobDescriptionId = this.jobDescriptionId
      }

      if (this.isJobDescription) {
        filter.isJobDescription = true
      }

      if (this.isDetail) {
        filter.isDetail = true
      }

      if (this.isProfile) {
        filter.isProfile = true
      }

      return filter
    },
    fetchData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.setRetaining()
      this.items = []
      this.isFetching = true

      this.$apollo.query({
        query: LIST_REPORT_PAYROLL(this.templateType),
        variables: this.serializeInput(),
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.items = res.data.items.map(item => this.serializeData(item))
      })
      .catch((err) => {
        this.$toasted.global.error(err)
      })
      .finally(() => {
        this.isFetching = false
      })
    },
    downloadData () {
      this.$axios.get(this.url, {
        params: this.serializeInput(),
        responseType: 'blob'
      })
      .then(res => {
        let filename = 'payroll_eca_report_payroll.pdf'
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      }).catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    printData (deviceId) {
      if (!deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      this.$axios.get(this.url, {
        params: {
          ...this.serializeInput(),
          deviceId
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    }
  },
  components: {
    DocConfigFormContact,
    DocConfigFormJobDescription,
    ListReportPrinter
  }
}
</script>

<style lang="css" scoped>
</style>
